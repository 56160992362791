<template lang="html">
    <setting-block-layout
        :loading="btnSaveLoader"
        @save="onSave"
    >
        <template slot="body">
            <div class="profile wrapper">
                <div class="profile-header-block">
                    <RadiusLoader v-if="loading" />
                    <div
                        v-else
                        class="payments-add__container"
                    >
                        <form-select
                            v-if="!!types.length"
                            :value="selectedType"
                            :field.sync="selectedType"
                            :value-select="selectedType"
                            :items="types"
                            class="payments-add__select"
                            name="pay_method"
                            label="Payment Method"
                            @input="selectMethod"
                        />
                        <affiliate-payment-form
                            v-if="selectedType === 'wire'"
                            :ref="selectedType"
                            :fields="fields[selectedType]"
                            :form-data="formData[selectedType]"
                            @update="onUpdate($event, selectedType)"
                        />
                        <affiliate-payment-u-s-d-t
                            v-if="selectedType === 'usdt'"
                            :ref="selectedType"
                            :fields="fields[selectedType]"
                            :form-data="formData[selectedType]"
                            @update="onUpdate($event, selectedType)"
                        />
                        <affiliate-payment-stripe
                            v-if="selectedType === 'stripe'"
                            :ref="selectedType"
                            :fields="fields[selectedType]"
                            :form-data="formData[selectedType]"
                            @update="onUpdate($event, selectedType)"
                        />
                        <affiliate-payment-paypal
                            v-if="selectedType === 'paypal'"
                            :ref="selectedType"
                            :fields="fields[selectedType]"
                            :form-data="formData[selectedType]"
                            @update="onUpdate($event, selectedType)"
                        />
                    </div>
                </div>
            </div>
        </template>
    </setting-block-layout>
</template>
<script>

import SettingBlockLayout from '@/components/account-settings/SettingBlockLayout';
import RadiusLoader from '@/components/common/RadiusLoader.vue'

import {
    affiliateListAvailable,
    getPaymentsAffiliateListConnected,
    paymentWireCreateOrUpdate,
    paymentUsdtCreateOrUpdate,
    paymentStripeCreateOrUpdate,
    paymentPaypalCreateOrUpdate
} from '@/services/referral';

import { eventBus } from '@/helpers/event-bus';

import AffiliatePaymentForm from './payment/AffiliatePaymentForm.vue';
import AffiliatePaymentUSDT from './payment/AffiliatePaymentUSDT.vue';
import AffiliatePaymentStripe from './payment/AffiliatePaymentStripe.vue';
import AffiliatePaymentPaypal from './payment/AffiliatePaymentPaypal.vue';

export default {
    name: 'AffiliatePaymentsAddMethod',
    components: {
        AffiliatePaymentUSDT,
        AffiliatePaymentStripe,
        AffiliatePaymentPaypal,
        AffiliatePaymentForm,
        SettingBlockLayout,
        RadiusLoader
    },
    metaInfo: {
        title: 'Payment Method'
    },
    data() {
        return {
            type: 'usdt',
            showModal: false,
            payments: null,
            activeTab: null,
            types: [],
            selectedType: 'wire',
            fields: {},
            connectedMethods: {},
            formData: {},
            loading: false,
            savePayoneer: false,
            btnSaveLoader: false
        }
    },
    async created() {
        this.loading = true
        await this.getTypes()
        if (this.$route.params.type) {
            this.selectedType = this.$route.params.type
        }
        if (this.$route.params.editId) {
            await this.getMethodForEdit()
        }
        this.loading = false
        if (this.$route.query.method) {
            this.selectMethod(this.$route.query.method)
        }
    },
    methods: {
        async affiliateListAvailable() {
            let data
            try {
                data = await affiliateListAvailable()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
            return data
        },
        async getPaymentsAffiliateListConnected() {
            let data
            try {
                data = await getPaymentsAffiliateListConnected()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
            return data
        },
        async paymentWireCreateOrUpdate(payload) {
            try {
                this.btnSaveLoader = true
                await paymentWireCreateOrUpdate(payload)
                this.$router.push({ name: 'profile_affiliate' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.btnSaveLoader = false
            }
        },
        async paymentUsdtCreateOrUpdate(payload) {
            try {
                this.btnSaveLoader = true
                await paymentUsdtCreateOrUpdate(payload)
                this.$router.push({ name: 'profile_affiliate' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.btnSaveLoader = false
            }
        },
        async paymentStripeCreateOrUpdate(payload) {
            try {
                this.btnSaveLoader = true
                await paymentStripeCreateOrUpdate(payload)
                this.$router.push({ name: 'profile_affiliate' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.btnSaveLoader = false
            }
        },
        async paymentPaypalCreateOrUpdate(payload) {
            try {
                this.btnSaveLoader = true
                await paymentPaypalCreateOrUpdate(payload)
                this.$router.push({ name: 'profile_affiliate' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.btnSaveLoader = false
            }
        },
        async onSave() {
            if (this.preValidate()) {
                switch (this.selectedType) {
                case 'wire':
                    await this.paymentWireCreateOrUpdate(this.formData[this.selectedType])
                    break;
                case 'usdt':
                    await this.paymentUsdtCreateOrUpdate(this.formData[this.selectedType])
                    break;
                case 'stripe':
                    await this.paymentStripeCreateOrUpdate(this.formData[this.selectedType])
                    break;
                case 'paypal':
                    await this.paymentPaypalCreateOrUpdate(this.formData[this.selectedType])
                    break;
                default:
                    break;
                }
            }
        },
        async getMethodForEdit() {
            const response = await this.getPaymentsAffiliateListConnected()
            const { editId, type } = this.$route.params
            this.formData[type] = response.list.find(({ id }) => id === editId)
        },
        async getTypes() {
            const { types, fields } = await this.affiliateListAvailable()
            this.types = [...types.map(({ key, title }) => ({
                text: title,
                value: key
            }))]
            this.fields = fields
        },
        onUpdate(payload, type) {
            this.$set(this.formData, type, {
                ...this.formData?.[type],
                ...payload
            })
        },
        preValidate() {
            let hasError = true
            this.$refs[this.selectedType].$v.$touch()
            hasError = !this.$refs[this.selectedType].$v.$anyError
            return hasError
        },
        selectMethod(type) {
            this.selectedType = type
            this.$router.push({ query: { method: type } })
        }
    }
}
</script>
